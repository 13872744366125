import React, { useEffect, useRef } from 'react';
import { Link } from 'react-scroll';
import './Home.css';
import { SparklesCore } from "../ui/sparkles";

const Home = () => {
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const hasAnimated = useRef(false);

  useEffect(() => {
    if (hasAnimated.current) return;
    hasAnimated.current = true;

    const titleElement = titleRef.current;
    const subtitleElement = subtitleRef.current;

    const animateText = (element, text, delay = 100) => {
      element.innerHTML = '';
      text.split('').forEach((char, i) => {
        setTimeout(() => {
          element.innerHTML += char;
        }, delay * i);
      });
    };

    animateText(titleElement, "Hello, I'm Subash.");
    setTimeout(() => {
      animateText(subtitleElement, "I'm a Software Engineer.");
    }, 2000);
  }, []);

  return (
    <div className="home" id="home">
      <div className="sparkles-container">
        <SparklesCore
          id="tsparticlesfullpage"
          background="transparent"
          minSize={0.6}
          maxSize={1.4}
          particleDensity={100}
          className="w-full h-full"
          particleColor="#FFFFFF"
        />
      </div>

      <div className="home-content">
        <h1 ref={titleRef} aria-hidden="true"></h1>
        <p ref={subtitleRef}></p>
        
        <div className="scroll-down">
          <Link to="about" smooth={true} duration={500} className="scroll-link">
            <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 5V19M12 19L5 12M12 19L19 12" stroke="#16e0bd" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;