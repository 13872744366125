import React, { useState } from 'react';
import './ContactModal.css'; // Optional: You can add a CSS file for styling

const ContactModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [submitStatus, setSubmitStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitStatus('Thank you for your message!');
    setFormData({ name: '', email: '', message: '' }); // Clear form data

    // fetch('http://localhost:5001/api/contact', { // Ensure this URL matches your API
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(formData),
    // })
    // .then(response => response.json())
    // .then(data => {
    //   if (data.success) {
    //     setSubmitStatus('Thank you for your message!');
    //     setFormData({ name: '', email: '', message: '' }); // Clear form data
    //   } else {
    //     setSubmitStatus('There was a problem submitting your message.');
    //   }
    // })
    // .catch(error => {
    //   console.error('Error:', error);
    //   setSubmitStatus('There was a problem submitting your message.');
    // });
  };

  if (!isOpen) return null;

  return (
    <section id="contact">
      <div className="modal-overlay">
        <div className="modal-content">
          <button className="modal-close" onClick={onClose}>×</button>
          <h2>Contact Me</h2>
          {submitStatus ? (
            <p>{submitStatus}</p>
          ) : (
            <form onSubmit={handleSubmit}>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Message:
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </label>
              <button type="submit">Send</button>
            </form>
          )}
        </div>
      </div>
    </section>
  );
};

export default ContactModal;
