import React from 'react';
import './Footer.css'; // Optional: You can add a CSS file for styling

const Footer = () => {
  return (
    <footer>
      <p>&copy; 2024 Subash. All rights reserved.</p>
      <div className="social-links">
        <a href="https://www.linkedin.com/in/subashbhusal" target="_blank" rel="noopener noreferrer">
          <img src="/images/linkedin-logo.png" alt="LinkedIn" className="social-icon" />
        </a>
        <a href="https://github.com/subashbhusall" target="_blank" rel="noopener noreferrer">
          <img src="/images/github-logo.png" alt="GitHub" className="social-icon" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
