import React, { useState } from 'react';
import About from './About/About';
import Projects from './Projects/Projects';
import Experience from './Experience/Experience';
import ContactModal from './Contact/ContactModal';
import Home from './Home/Home';
import './MainPage.css'; 

const MainPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div>
      <Home />
      <About />
      <Experience />
      <Projects />
      <section id="contact-section">
        <div className="contact-button-container">
          <button onClick={openModal} className="contact-button">Contact Me</button>
        </div>
        <ContactModal isOpen={isModalOpen} onClose={closeModal} />
      </section>
    </div>
  );
};

export default MainPage;
