import React from 'react';
import './About.css';

const About = () => {
  const skills = [
    { name: 'Java', icon: 'java-icon.png' },
    { name: 'SQL', icon: 'sql-icon.png' },
    { name: 'AWS', icon: 'aws-icon.png' },
    { name: 'Python', icon: 'python-icon.png' },
    { name: 'Docker', icon: 'docker-icon.png' },
    { name: 'Git', icon: 'git-icon.png' },
    { name: 'C++', icon: 'cpp-icon.png' },
    { name: 'React', icon: 'react-icon.png' },
    { name: 'Postman', icon: 'postman-icon.png' }
  ];

  return (
    <section id="about">
      <div className="about-container">
        <h2 className="section-title" id="section-title">About</h2>
        <div className="about-content">
          <div className="about-text">
            <div id ="profile-logo">
              <img id = "profile-logo-pic" src='/images/profile-logo.png' alt="Profile Icon" className="profile-icon" />
            </div>
            <p>
              I am a Software Engineer based in Chicago, IL, specializing in backend development, data pipelines, and optimization. I hold a Master's degree in Computer Science from Georgia Institute of Technology and a Bachelor's degree from the University of Illinois at Chicago.
            </p>
          </div>
          <div className="skills-grid">
            {skills.map((skill, index) => (
              <div key={index} className="skill-item">
                <img src={`/images/${skill.icon}`} alt={skill.name} className="skill-icon" />
                <p>{skill.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;