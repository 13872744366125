import React, { useState } from 'react';
import './Experience.css';

const ExperienceItem = ({ company, role, period, responsibilities, logoUrl }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`experience-item ${isOpen ? 'open' : ''}`}>
      <button
        className="experience-header"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        <div className="logo-container">
          <img src={logoUrl} alt={`${company} logo`} className="company-logo" />
        </div>
        <div className="header-content">
          <div className="header-left">
            <h3 className="company-name">{company}</h3>
            <p className="job-title">{role}</p>
          </div>
          <p className="job-period">{period}</p>
        </div>
        <div className="toggle-icon-wrapper">
          <svg
            className="toggle-icon"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </button>
      <div className="experience-details">
        <ul className="responsibilities-list">
          {responsibilities.map((item, index) => (
            <li key={index} className="responsibility-item">
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Experience = () => {
  const experiences = [
    {
      company: 'Caterpillar Inc.',
      role: 'Software Engineer',
      period: 'Jun 2022 – Present',
      logoUrl: '/images/cat-logo.png',
      responsibilities: [
        'Architected and implemented Java-based RESTful APIs, reducing server response times by 20%.',
        'Designed scalable AWS-based data pipelines with 99.99% reliability.',
        'Optimized database performance, resulting in a 30% improvement in query execution time.',
        'Developed CI/CD pipelines with a 99.9% build success rate.',
        'Implemented auto-scaling strategies for RDS and ECS instances, reducing operational costs by 15%.'
      ]
    },
    {
      company: 'UIC Research Lab',
      role: 'Research Assistant',
      period: 'Jan 2022 – May 2022',
      logoUrl: '/images/uic-logo.png',
      responsibilities: [
        'Engineered Python scripts that reduced computation time for prime number analysis by 40%.',
        'Developed a benchmarking framework for primality testing algorithms.',
        'Analyzed over 1 million prime number sequences, uncovering new patterns.',
        'Authored a comprehensive 20-page research paper in LaTeX.'
      ]
    },
    {
      company: 'University of Illinois',
      role: 'Artificial Intelligence Intern',
      logoUrl: '/images/ui-logo.png',
      period: 'May 2021 – Aug 2021',
      responsibilities: [
        'Conducted market research on AI in Occupational Safety and Health (OSH), identifying key trends.',
        'Developed a 6-module online course on AI in OSH with a 95% satisfaction rate.',
        'Authored 10 case studies on AI products in OSH.',
        'Designed an interactive learning platform, increasing engagement by 40%.'
      ]
    }
  ];


  return (
    <section id="experience">
      <div className="container">
        <h2 className="section-title">Experience</h2>
        <div className="experience-list">
          {experiences.map((exp, index) => (
            <ExperienceItem key={index} {...exp} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Experience;