import React from 'react';
import './Projects.css';

const projects = [
  {
    project_title: 'Auction System',
    project_description: 'Led a team of 5 software engineers in developing a comprehensive online auction system using Python (Tkinter for frontend, backend) and MySQL. Implemented key features including user authentication, item listing creation, bidding functionality, and admin reports, while ensuring a seamless user experience and robust database management.',
    start_date: '2023-01-01',
    end_date: '2023-06-30',
    tools_used: 'Python, Tkinter, MySQL',
    project_link: 'https://github.gatech.edu/cs6400-2024-01-spring/cs6400-2024-01-Team040'
  },
  {
    project_title: 'Fingerprint Door Lock',
    project_description: 'Designed an Arduino project that used fingerprint image as identification to open/close an electronic solenoid door lock. Led a group of three students and presented the project demo to the engineering professions at the annual UIC Engineering Expo.',
    start_date: '2022-09-01',
    end_date: '2022-12-15',
    tools_used: 'Arduino, Fingerprint Sensor, Solenoid Lock',
    project_link: 'https://youtu.be/3IriA0cW1rI'
  },
  {
    project_title: 'Puzzle15 Game',
    project_description: 'Developed a slide puzzle game using JavaFX library, which lets users slide tiles to get the numbers in order 0 to 15. Implemented A* algorithm solution, which animated the shortest path to the solution.',
    start_date: '2021-05-01',
    end_date: '2021-08-30',
    tools_used: 'JavaFX, A* Algorithm',
    project_link: 'https://github.com/subashbhusall/Puzzle15'
  }
];

const Projects = () => {
  return (
    <section id="projects">
      <h2 className="section-title">Projects</h2>
      <div className="project-list">
        {projects.map((project, index) => (
          <div key={index} className="project-item">
            <h3>{project.project_title}</h3>
            <p>{project.project_description}</p>
            <p className="tools"><strong>Tools Used:</strong> {project.tools_used}</p>
            <a href={project.project_link} target="_blank" rel="noopener noreferrer">View Project</a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Projects;
